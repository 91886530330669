.dropdownWraper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .selector {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
