@import "src/assets/scss/variables";

//* ============ COLOR GETTER =========== */
@function getColor($map, $key) {
    @if map-has-key($map, $key) {
        @return map-get($map, $key);
    }

    @warn "The key #{$key} is not in the map #{$map}";

    @return null;
}
//* ============ END COLOR GETTER =========== */

//* ============ NESTED TABLE =========== */
@mixin nestedTable() {
  .ant-table-thead,
  .ant-table-tbody {
    .ant-table-cell {
      color: #4A4A4A;
      font-size: 12px;
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      font-weight: 600;
    }
  }

  tr.ant-table-expanded-row > td {
    background-color: $color-lightest;
  }

  &__child-table {
    .ant-table-content {
      table {
        border-left: 6px solid $color-primary;
      }
    }

    .ant-table-thead {
      .ant-table-cell {
        background-color: getColor($registration, 50);
      }
    }

    .ant-table-tbody {
      .ant-table-row:hover {
        .ant-table-cell {
          background-color: getColor($secondary, 50) !important;
        }
      }
    }
  }
}
/* ============ END NESTED TABLE =========== */
