@import "src/assets/scss/mixins-and-functions";

.channel-table {
  @include nestedTable;
}

.activeFilter {
  margin-right: 10px;
}

.message {
  display: flex;
  flex-direction: column;
  margin-left: calc(100%);
  top: 10;
  text-align: left;
  padding-right: 1px;
  width: 300px;
  height: 64px;
  margin-right: 51px;
  margin-bottom: 20px;
}

.message:first-child {
  margin-top: calc(75px + 32px);
}

.message:nth-child(1n + 2) {
  margin-top: 10px;
}
