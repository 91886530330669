.select-create {
  color: rgba(24, 144, 255, 1);
  background-color: rgba(255, 255, 255, 0);
  border: none;
  padding: 1%;
  padding-left: 10px;

  p {
    margin: 0px;
    text-decoration: underline;
  }
}
