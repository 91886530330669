.tags-filter-container {
  padding: 10px;
  border-radius: 5px;
}
.buttons {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  .centerFilterText {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 70px;
  }
  .okButton {
    margin-right: 5px !important;
  }
  .clearButton {
    margin-right: 5px !important;
  }
}
