.btn-upload-img {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  width: 100%;
  height: auto;
  cursor: pointer;

  label {
    display: flex;
    flex-direction: column;
    color: rgba(217, 217, 217, 1);
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;

    span {
      font-size: 20px;
    }

    p {
      margin-top: 5px;
    }
  }
}
