.confirmation-modal-ul {
  list-style-type: none;

  li {
    margin-bottom: 10px;
  }

  span {
    margin-left: 10px;
  }
}
