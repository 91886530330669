@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;800&display=swap');

/*
FONT WEIGHT
Semibold: 600
Medium: 500
Regular: 400
*/
body {
    margin: 0;
    font-family: $main-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
