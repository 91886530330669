.container-edit-ad {
  display: flex;

  .sidebar-edit-ad {
    width: 256px;
    min-height: calc(100vh - 75px - 80px);
    background-color: #ffffff;
  }

  .content-edit-ad {
    width: calc(100% - 256px);
    min-height: calc(100vh - 75px - 80px);
  }
}

.modal-text {
  color: rgba(74, 144, 226, 1);
  margin: 26px 20px 0px 15px;
}

.modal-ad-edit {
  .ant-modal-footer {
    border-top: 1px solid #4a90e2;
  }

  .btn-footer-modal {
    border-color: rgba(74, 144, 226, 1);
    color: rgba(74, 144, 226, 1);
  }
}

.spin-loading {
  width: 80%;
  margin: auto;
  text-align: center;
}
