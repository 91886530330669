.container-frequecies {
  padding-left: 26px;

  .container-search {
    margin-bottom: 36px;

    .wrapper-search {
      .search-block {
        input {
          margin-right: 24px;
        }
      }

      .search-set {
        display: flex;
        flex-direction: row;
        margin-top: 4px;
        align-items: flex-end;

        button {
          width: 100px;
        }

        .search-line {
          display: flex;
          margin-left: 24px;

          span {
            margin: auto;
            margin-right: 12px;
          }

          input {
            margin-right: 12px;
          }
        }
      }
    }

    .search-table {
      margin-top: 71px;
      width: 85%;

      .header-tags {
        margin-bottom: 11px;
        display: flex;
        justify-content: flex-start;

        .container-tags {
          width: 100%;
          .wrapprer-tags {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .ant-tag {
              background-color: #e5f3fd;
              color: #4a90e2;
              border: none;

              span {
                margin-left: 7px;
              }
            }
          }
        }
      }

      table {
        width: 85% !important;
      }
    }

    .focus-visible {
      border-color: #d9d9d9 !important;
      border-right: none;
      box-shadow: none !important;
    }

    .ant-input {
      border-color: #d9d9d9;
    }
  }

  .wrapper-title {
    p {
      color: #9ccff9;
      font-size: 16px;
    }
  }

  .wrapper-taps {
    justify-content: center;
    display: flex;

    .ant-tabs-tab {
      padding: 0px;
      font-size: 18px;
      color: #9ccff9;
      margin: 0 62px 0 0;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #4a90e2;
    }
    .ant-tabs-top > .ant-tabs-nav,
    .ant-tabs-bottom > .ant-tabs-nav,
    .ant-tabs-top > div > .ant-tabs-nav,
    .ant-tabs-bottom > div > .ant-tabs-nav {
      margin: 0px;
    }
  }

  .wrapper-table {
    width: 838px;
    margin: auto;

    table {
      width: 838px !important;
    }
  }

  .wrapper-search,
  .wrapper-table {
    table {
      text-align: center;

      .ant-table-thead > tr > th {
        text-align: center !important;
      }
    }
  }

  .ant-divider-horizontal {
    margin: 8px 0px 35px 0px;
  }
}

.wrapper-button-table {
  display: flex;
  justify-content: left;

  .title {
    margin-right: 10px;
  }

  button:first-child {
    margin-right: 10px;
  }

  .ant-btn-sm {
    padding: 0 20px;
  }
}

.message {
  margin-top: calc(75px + 32px);
  float: right;
  text-align: left;
  padding-right: 1px;
  width: 305px;
  height: 64px;
  margin-right: 57px;
}

.ant-message
  > div
  > .ant-message-notice
  > .ant-message-notice-content
  > .ant-message-custom-content {
  display: flex;
}

.ant-message
  > div
  > .ant-message-notice
  > .ant-message-notice-content
  > .ant-message-custom-content
  span:first-child {
  margin: auto;
  margin-right: 9px;
}
