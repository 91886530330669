.container-stopper {
  .header-stopper {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .wrapper {
      p {
        color: #9ccff9;
        font-size: 16px;
      }
    }

    .add-stopper {
      .ant-btn-primary {
        width: 204px;
        height: 44.91px;
      }
      span {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .wrapper-table-stopper {
    .wrapper-delete {
      width: 1rem;
      margin-left: 23px;

      button {
        width: 98px;
        height: 28px;
        padding: 0px;
        border: 1px solid #4a90e2;
        color: #4a90e2;
        font-size: 14px;
      }
    }

    .viewers-stopper {
      width: 6rem;
      margin: auto;

      .wrapper-input-viewers {
        input {
          width: 119px;
          height: 28px;
          color: #808080;
          font-size: 14px;
        }
      }
    }
    .wrapper-frequecy-stopper {
      width: 6rem;
      margin: auto;

      .select-frquency {
        .ant-select {
          width: 86.36px;
          height: 28px;
          color: #808080;
          font-size: 14px;
        }

        .ant-select-arrow {
          top: 55%;
          font-size: 10px;
        }

        .ant-select-selector {
          height: 25px;
        }
      }
    }

    .ant-table-content {
      table {
        thead {
          tr {
            th {
              text-align: center;
            }
          }
        }
        tbody {
          tr {
            td {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
