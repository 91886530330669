@import "src/assets/scss/mixins-and-functions";

.moderation-table {
  @include nestedTable;

  .highlight-campaign {
    color: #1890ff;
    cursor: pointer;
  }
}
