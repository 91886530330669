.top-bar {
  height: 75px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__collapse_container {
    margin-left: 20px;
    align-self: center;

    &__btn {
      border: none !important;
      font-size: 20px !important;
      background-color: white !important;
    }
  }

  &__actions {
    margin-right: 20px;
    align-self: center;
    display: flex;
    justify-content: space-between;

    &__user {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      &__email {
        margin-left: 10px;
      }
    }

    &__logout-btn {
      display: flex !important;

      border: none !important;
      outline: none !important;
      background-color: white !important;
      box-shadow: none !important;

      span {
        line-height: 100%;
      }
    }
  }
}
