.container-segmentation {
  padding: 40px 32px 0px 32px;
  display: flex;
  flex-direction: column;

  .ant-card {
    margin-bottom: 35px;
  }

  .disabled-card {
    border: 1px solid rgba(228, 228, 228, 0.7);
    background: #FAFAFA;
    border-radius: 4px;
  }

  .hide-button {
    display: none !important;
  }

  .actionsButtons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    /* horizontal spacing */
    margin-bottom: 15px;
  }

  .content-column {
    display: flex;
    flex-direction: column;
    padding-left: 0em;

    .inner-content {
      display: flex;
      flex-direction: column;
    }

  }

  .content-title {
    padding-bottom: 1.5em;
    padding-left: 0em;

    .title {
      margin-left: 0em !important;
    }
  }

  .content-row {
    display: flex;
    flex-direction: row;
    padding-left: 0em;
  }

  .content {
    display: flex;

    p {
      margin: 0px;

      strong {
        text-decoration: underline;
      }
    }

    .disabled {
      color: #BEBEBE !important;
    }

    .title {
      color: rgba(74, 144, 226, 1);
      font-size: 14px;
      font-weight: 700;
      margin-left: 13px;
      margin-right: 13px;
    }

    .checkbox-wrapper div {
      display: flex;
      flex-direction: row;
      padding-top: 1.5em;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.positon-btn-segmentation {
  justify-content: space-between;
}