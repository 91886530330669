.ant-table-expanded-row {
  .ant-table-cell {
    padding: 0px;
  }
}

.warningButton button.ant-switch.ant-switch-checked {
  background-color: #f1c21b;
}

.futureWarningButton button.ant-switch.ant-switch-checked {
  background-color: #f7e090;
}

.futureCampaingOn button.ant-switch.ant-switch-checked {
  background-color: #007ffe;
  opacity: 0.4;
}

.futureCampaingOff button.ant-switch {
  background: #bfbfbf;
  opacity: 0.4;
}

.ant-table-filter-column-title {
  flex: none;
  padding: 16px 1em 16px 16px;
}

.ant-table-filter-trigger-container {
  position: relative;

  span {
    span {
      svg {
        color: #4a4a4a;
      }
    }
  }
}

.no-new-line {
  white-space: nowrap !important;
}

.ant-divider-horizontal {
  margin: 0px;
  color: #d4e5f9;
  border-color: #d4e5f9;
}

.table-row-last {
  font-weight: 700;
}
.totals {
  font-weight: 700;
}

thead[class*="ant-table-thead"] th {
  font-size: 12px;
  font-weight: 600;
}

.table-text {
  font-size: 12px;
}
