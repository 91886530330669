.container-expanded {
  background-color: #d4e5f94d;
  padding-top: 22px;
  padding-left: 60px;

  .content-expanded {
    display: flex;

    .first-message {
      width: 25%;
      text-align: left;

      .platform-text {
        font-size: 14px;
        font-weight: 500;
        font-family: Inter;
        padding-top: 0.25em;
        padding-left: 0.25em;
      }

      button {
        color: #1890ff;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
        font-weight: 600;
        border: none;
        background-color: rgba(255, 255, 255, 0);

        .anticon {
          font-size: 18px;
          margin-left: 7px;
          color: #1890ff !important;
        }
      }
    }

    .column-info-expanded,
    .final-column-info-expanded {
      .section-info-expanded {
        margin-bottom: 20px;

        p {
          margin: 0px;
          font-size: 14px;
        }

        p:first-child {
          font-weight: 400;
        }

        p:last-child {
          font-weight: 700;
        }
      }
    }

    .column-info-expanded {
      width: 30%;
      margin-right: 10px;
    }

    .change-status-ad {
      width: 20%;
      text-align: right;
      padding-right: 30px;

      .futureCampaingOn button.ant-switch.ant-switch-checked {
        background-color: #007FFE;
        opacity: 0.4;
      }

      .futureCampaingOff button.ant-switch {
        background: #BFBFBF;
        opacity: 0.4;
      }
    }
  }
}