@import "src/assets/scss/variables";

.moderation-page {
  &__filter {
    color: $color-primary;
    font-size: 16px;
    margin-top: $spacer / 2;
    margin-bottom: $spacer;
  }
}
