.container-nav-bar-ad {
  height: 70px;
  background-color: #ffffff;
  color: rgba(74, 144, 226, 1);
  display: flex;
  border: solid 1px rgba(0, 21, 41, 0.12);

  .wrapper-go-back {
    width: 256px;
    display: flex;
    vertical-align: middle;
    padding-left: 43px;

    .go-back {
      border: none;
      background-color: #ffffff00;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .header {
    width: calc(100% - 256px);
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 62px;

    p {
      font-size: 23px;
      font-weight: 500;
      margin: 0px;
    }
  }
}
