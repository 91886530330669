.container-revison {
  padding: 30px;

  .title {
    font-size: 16px;
    color: rgba(74, 144, 226, 1);
    font-weight: 700;
  }

  .budget-content {
    width: 600px;
    justify-content: space-between;
    margin-bottom: 10px;

    .icon {
      margin-top: 20px;
      font-size: 18px;
    }
  }

  .content {
    .sub-title {
      margin-bottom: 5px;
    }
  }

  .content-wrapper {
    margin-bottom: 18px;
  }

  .data-content {
    font-weight: 700;
  }

  .data-img {
    margin-top: 50px;
    width: 50%;
    justify-content: left;

    .img-content {
      width: 30%;
      margin-right: 24px;

      p {
        margin-bottom: 8px;
      }

      .ant-card-body {
        padding: 5px;
      }

      video {
        width: 100%;
      }
    }

    .video-content {
      width: 45%;
    }
  }
  .ant-card-body {
    padding-left: 30px;
  }

  .btn-save {
    margin-top: 28px;
    display: flex;
    justify-content: right;
  }
}

.revision-position-btn {
  justify-content: space-between;
  margin-top: 28px;
}
