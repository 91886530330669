.section-tittle {
  color: #4a90e2;
  font-size: 23px;
  font-weight: 400;
}

.add-campaing-button {
  width: 232px;
  height: 44.91px;
  margin-bottom: 43.09px;
  font-weight: 500;
  font-size: 16px;
}

.mb-1 {
  margin-bottom: 16px;
}

.space-element {
  justify-content: space-between;
  p {
    color: #9ccff9;
    font-size: 16px;
    margin-bottom: 24px;
  }
  .ant-picker {
    margin-bottom: 24px;
  }
}

.wiht-button {
  width: 141px;
  height: 32px;
  background-color: rgba(24, 144, 255, 1);
}
