/* ============ MENU =========== */
.ant-menu-horizontal {
  background-color: transparent;
  font-size: 18px;

  .ant-menu-item > a {
    color: getColor($registration, 200);
  }

  .ant-menu-item-selected > a {
    color: $color-primary;
    font-weight: 600;
  }

  & > .ant-menu-item:hover,
  & > .ant-menu-submenu:hover,
  & > .ant-menu-item-active,
  & > .ant-menu-submenu-active,
  & > .ant-menu-item-open,
  & > .ant-menu-submenu-open,
  & > .ant-menu-item-selected,
  & > .ant-menu-submenu-selected {
    color: $color-primary !important;
    border-bottom: 2px solid $color-primary !important;
  }
}
/* ============ END MENU =========== */

/* ============ PROGRESS =========== */
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: $color-primary;
}
/* ============ END PROGRESS =========== */

/* ============ DRAWER =========== */
.ant-drawer-header {
  border-bottom: 1px solid $color-primary;

  .ant-drawer-title {
    font-weight: 600;
    font-size: 20px;
    color: $color-primary;
  }
}

.ant-drawer-close {
  color: $color-primary;
}

@media (max-width: 519px) {
  .ant-drawer-content-wrapper {
    width: 90vw !important;
  }
}
/* ============ END DRAWER =========== */

/* ============ BUTTONS =========== */
.ant-btn-primary {
  background-color: $color-primary;

  &:hover {
    background-color: darken(getColor($registration, 600), 15);
    border-color: darken(getColor($registration, 600), 15);
  }
}
/* ============ END BUTTONS =========== */

/* ============  =========== */
tr.ant-table-expanded-row td:only-child.ant-table-cell {
  border-left: none;
}
/* ============ END  =========== */

/* ============ ALERT =========== */
.ant-alert.ant-alert-info {
  margin-bottom: $spacer;

  .ant-alert-message {
    color: $color-primary !important;
  }
}
/* ============ END ALERT =========== */

/* ============ PAGE HEADER =========== */
.ant-page-header-ghost {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
/* ============ END PAGE HEADER =========== */
