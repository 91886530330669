.modal-create {
  .ant-modal-content {
    .ant-modal-header {
      background: linear-gradient(
        90deg,
        rgba(78, 146, 223, 1),
        rgba(40, 91, 189, 1)
      ) !important;
      .ant-modal-title {
        color: #ffffff;
      }
    }

    .ant-modal-close-x {
      color: #ffffff;
    }

    .ant-modal-footer {
      border-color: rgba(74, 144, 226, 1);
      display: flex;
      justify-content: space-around;

      button {
        width: 45%;
      }
    }
  }
}

.message-modal-create {
  color: rgba(74, 74, 74, 1);
  font-weight: 600;
}

.modal-create-loading {
  margin-left: 10px;
}

.select-create {
  cursor: pointer;
}
