.container-channels {
  padding-top: 65px;
  padding-left: 43px;

  h1 {
    color: #4a90e2;
    font-size: 30px;
    font-weight: 400;
  }

  .activeFilter {
    background-color: #e5f3fd;
    color: #4a90e2;
    border: 0;
  }

  .clearFilters {
    box-shadow: none;
    text-shadow: none;
  }

  .filterWrapper {
    display: flex;
    flex-direction: row;

    .filters {
      :only-child .activeFilter {
        margin-right: 0;
      }
    }
    .filters {
      :not(:only-child) .activeFilter {
        margin-right: 20px;
      }
    }
  }
  .ant-col .ant-col-xs-24 {
    padding-left: 0 !important;
  }

  .marginLeft {
    margin-left: 20px;
  }

  .ant-table-thead {
    tr {
      th.ant-table-cell {
        padding: 0.7em 1em 0.7em 1em;
      }
    }
  }
}

.tableWrapper {
  width: auto;
}
