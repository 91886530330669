#login-page {
  height: 100%;
  display: flex;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    width: 33%;

    .container-login {
      text-align: center;
    }
  }

  .ant-tabs-nav-list {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .ant-tabs-tab {
    margin-right: 0;
  }
}
