.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #002140;

  &__logo {
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  &__logo-collapsed {
    display: flex;
    margin-top: 17.5px;
    margin-bottom: 17.5px;
    font-weight: bold;
    color: white;
    font-size: 20px;
    height: 40px;
    width: 40px;
  }

  &__menu {
    flex: 2;
    height: 100%;

    .ant-menu {
      height: 100%;
    }
  }
}
