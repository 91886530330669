.container-edti-stopper {
  width: 50%;
  margin: auto;

  .edit-btn {
    width: 98px;
    height: 28px;
    border: 1px solid #4a90e2;
    color: #4a90e2;
    font-size: 14px;
  }

  .wrapper-btns {
    display: flex;
    justify-content: space-evenly;

    .ant-btn {
      padding: 0;
      width: 98px;
      height: 28px;

      span {
        font-size: 14px;
      }
    }
  }
}
