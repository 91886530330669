.container-config-ads {
  padding: 30px 32px 0px 32px;

  .ant-card {
    margin-bottom: 30px;
    border-radius: 5px;
  }

  .ant-card-body {
    padding-left: 55px;
    padding-right: 89px;
  }

  .title {
    font-size: 16px;
    color: rgba(74, 144, 226, 1);
    font-weight: 700;
  }

  .row {
    display: flex;
    margin-bottom: 25px;

    p {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .description {
    justify-content: space-between;

    div {
      width: 45%;
      input {
        width: 100%;
      }

      .ant-select {
        width: 100%;
      }
    }
  }

  .buget {
    width: 60%;

    div {
      margin-right: 31px;
    }

    .date-time {
      margin-right: 0px;
    }

    input {
      width: 120px;
    }

    .icon {
      margin-top: 35px;
      font-size: 16px;
    }

    .result-view {
      background-color: #f4f4f4;
      border: none;
      padding: 5px;
      width: 100%;
    }

    .date-picker {
      width: 200px;
    }
  }

  .btn-save {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-bottom: 20px;
  }

  .error-message {
    color: red;
    font-weight: 500;
    font-size: 13px;
  }
}

.position-timer {
  align-self: end;
}

.position-btn {
  justify-content: end;
  margin-bottom: 20px;
}

.text-alert-message {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
}

.ant-alert.ant-alert-info {
  margin-bottom: 8px;
  width: 100% !important;
}
