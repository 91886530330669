.content-creativity {
  padding: 60px;
  height: calc(100vh - 70px - 75px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .btn-content-creativity {
    display: flex;
    justify-content: end;
  }

  .card-content-creativity {
    .ant-card-body {
      display: flex;

      .edit-content-creativity {
        width: 50%;

        div {
          margin-bottom: 12px;
          p {
            margin-bottom: 8px;
            font-weight: 600;
          }
        }
      }

      .img-video-creativity {
        width: 50%;
        justify-content: center;
        display: flex;

        .img-content {
          width: 40%;
          margin-left: 12px;
          margin-right: 12px;

          p {
            margin-bottom: 8px;
            font-weight: 600;
          }

          .ant-card-body {
            padding: 5px;
          }

          video {
            width: 100%;
          }
        }
      }
    }
  }

  .content-message {
    display: flex;
    justify-content: space-between;

    .error-message {
      color: #da1e28;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .counter {
    color: rgba(0, 0, 0, 0.25);
    text-align: right;
  }

  .text-alert-message {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 400;
    font-family: Inter;
  }
}
