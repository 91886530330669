.dropdownWraper {
  display: flex;
  flex-direction: row;

  .nameLabel {
    display: flex;
    flex-direction: column;
  }
  .selector {
    display: flex;
    flex-direction: column;
  }
}
