.steps {
  border: solid 1px rgba(0, 21, 41, 0.12);
  text-align: center;
  font-size: 14px;
  padding: 20px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  width: 100%;

  p {
    font-weight: 700;
    margin: 0px;
    margin-left: 20px;
    text-align: left;
  }
  ul {
    margin-top: 1rem;
    text-align: left;
  }
}

.active {
  border-left: solid 4.5px rgba(74, 144, 226, 1);
}

.desactive {
  color: rgba(0, 0, 0, 0.247) !important;
}
