.dashboard-layout {
  min-height: 100vh;
  width: 100%;
  display: flex;
  background-color: rgb(240, 242, 245);

  &__top-navigation {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__main {
    flex: 1;
    margin: 30px;
  }
}
