.tags-filter-container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  justify-items: center;
  width: 100%;

  .form-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 0;
    padding: 0;

    .form-wrapper {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      margin: 0;
      padding: 0;

      .textarea-wrapper {
        margin: 0;
        padding: 0;
        margin-bottom: 10px;

        .text-input {
          resize: none;
          width: 100%;
        }
      }
      .form-button-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin: 0;
        padding: 0;

        .button-reset {
          width: 49%;
          margin-right: 2%;
          color: #40a9ff;
          border-color: #40a9ff;
        }

        .button-ok {
          width: 49%;
        }
      }
    }
  }

  .filter-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .button-wrapper {
      display: flex;
      flex-direction: column;
      max-height: 300px;
      overflow: auto;
      width: 100%;

      .inner-button {
        margin-bottom: 5%;
      }
    }
  }
}
