.options-filter-container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  justify-items: center;
  padding: 10px;
  width: 170px;

  .buttonWrapp {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin: 0;
    margin-top: 10px;
    padding: 0;

    .button-reset {
      width: 49%;
      margin-right: 2%;
      color: #40a9ff;
      border-color: #40a9ff;
    }

    .button-ok {
      width: 49%;
    }
  }
}
