@import "src/assets/scss/variables";
@import "src/assets/scss/mixins-and-functions";

.modal {
  padding: 0;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  border-radius: 2px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;

  button.ant-modal-close {
    visibility: hidden;
  }

  &--size-eager {
    max-width: initial !important;
    width: calc(100vw - 40px) !important;
    height: calc(100vw - 40px) !important;
  }

  &--size-jumbo {
    max-width: initial !important;
    width: 90vw !important;
  }

  &--size-big {
    width: 75vw !important;
  }

  &--size-small {
    width: 40vw !important;
  }

  &--size-s {
    width: 30vw !important;
  }

  &--size-xs {
    width: 26vw !important;
  }

  div.ant-modal-body {
    color: #4a90e2;
  }

  .ant-modal-header {
    border-bottom: 1px solid $color-primary;
    padding: $spacer / 2;
    height: 50px;
  }

  .ant-modal-title {
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $color-primary;
  }

  .ant-modal-footer {
    border-top: 1px solid $color-primary;
    padding: $spacer / 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button.ant-btn.ant-btn-primary {
      box-shadow: none;
      span {
        color: white !important;
      }
    }
    &:empty {
      display: none;
    }
    button.ant-btn {
      border-color: #4a90e2 !important;
      box-shadow: none;
      span {
        color: #4a90e2 !important;
      }
    }
    &:empty {
      display: none;
    }
  }

  @media (min-width: 600px) {
    min-width: 500px !important;
  }
  @media (max-width: 600px) {
    min-width: 90% !important;

    .ant-modal-body {
      height: min-content !important;
    }
  }
}
